import React from "react";
import styled from "styled-components";
import homeBg from "src/assets/images/home-bg.svg";

interface BackgroundImageProps {
  imageUrl?: string;
}

const StyledBackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  background-image: url(${(props) => props.imageUrl});
  background-size: 126% auto;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const HomeBg: React.FC<BackgroundImageProps> = ({ imageUrl = homeBg }) => {
  return <StyledBackgroundImage imageUrl={imageUrl} />;
};

export default HomeBg;
