import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EbAccountPanel from "./account-panel/EbAccountPanel";
import EbShareButton from "./EbShareButton";
import { observer } from "mobx-react";
import EbBar from "./energy-bar/EbBar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { EbActionBox } from "./action/EbActionBox";
import { useLocation, useSearchParams } from "react-router-dom";
import { EbHelpPopup } from "./help/EbHelpPopup";
import { EbHelpLoginPopup } from "./help/EbHelpLoginPopup";
import { EbHelpDonePopup } from "./help/EbHelpDonePopup";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import copy from "copy-to-clipboard";
import { EbBarClaimPopup } from "./energy-bar/EbBarClaimPopup";
import { EbCopyPopup } from "./EbCopyPopup";
import { EbBackpackPopup } from "./backpack/EbBackpackPopup";
import { EbOpenOrbOverlay } from "./EbOpenOrbOverlay";
import { EbOpenOrbSuccessPopup } from "./backpack/EbOpenOrbSuccessPopup";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { Unlock } from "src/pages/sign-up/unlock/Unlock";
import { KontosError, ebError } from "src/type/error";
import { openUrl, sleep } from "src/utils/helper";
import {
  EB_ACTION_STATUS_SUCCESS,
  EB_KEY_ACTION_CATEGORY,
} from "src/apis/types/energyBoostingTypes";
import { EbLackKeyActionPopup } from "./EbLackKeyActionPopup";
import { EbNoBoostTimesPopup } from "./EbNoBoostTimesPopup";
import { isSameKontosName } from "src/utils/zkkontosHelper";
import { EbNoBoostPreconditionPopup } from "./EbNoBoostPreconditionPopup";
import { EbNewStagePopup } from "./EbNewStagePopup";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { DEFAULT_DECIMAL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import { useStores } from "src/hooks/useStore";
import { runInAction } from "mobx";
import { AUTH_TIMEOUT } from "src/store/events/EbStore";
import Joyride, { CallBackProps, Step, STATUS } from "react-joyride";
import { useSetState } from "react-use";
import React from "react";
import {
  JoyRideToolTip,
  KontosText,
} from "src/components/onboarding/JoyRideTooltip";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { OnboardingType } from "src/store/localKeeper";

const Conatiner = styled.div<{ $hasClaimBtn: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  .eb-bar {
    height: 52px;
    margin: ${(props) =>
      props.$hasClaimBtn ? "12px 24px 56px 24px" : "12px 24px 31px 24px"};
  }
`;

const EbActionWrapper = styled.div`
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const EbBarWrapper = styled.div`
  position: relative;
`;

const EbbarOnboardingHelper = styled.div`
  position: absolute;
  top: -5px;
  left: 8px;
  height: calc(100% + 18px);
  width: calc(100% - 16px);
`;

const EbShareBtnWrapper = styled.div`
  position: relative;
  margin: 28px 24px 32px 24px;
`;

type ClaimCahce = {
  threshold: number;
  energyOrbRewardType: number;
  energyOrbsRewardCount: number;
};

type boostCache = {
  boostValue: number;
};

type BeforeOpenCache = {
  energyOrbType: number;
};

type AfterOpenCache = {
  reward: ebTypes.Reward;
};

const FETCH_ACCOUNT_INTERVAL = 300;
const BAR_ANIMATION_TIME = 2;
const BOOST_WAITING_TIME = BAR_ANIMATION_TIME * 1000 + 600;

interface EbOnBoardingState {
  run: boolean;
  steps: Step[];
  spotLightRadius: string;
}

export const EnergyBoostingMain: React.FC = observer(() => {
  const { userStore, ebStore } = useStores();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inviter: string | undefined = searchParams.get("inviter")
    ? searchParams.get("inviter")?.replaceAll(".os", "") + ".os"
    : undefined;
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showPin, setShowPin] = useState<boolean>(false);
  const [showBackpack, setShowBackpack] = useState<boolean>(false);
  const [showNoAccount, setShowNoAccount] = useState<boolean>(false);
  // const [showBoostDoneSelf, setShowBoostDoneSelf] = useState<boolean>(false);
  const [showBoostDoneInviter, setShowBoostDoneInviter] =
    useState<boolean>(false);
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);
  const [showGetOrbs, setShowGetOrbs] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showOpenOrbSuccess, setShowOpenOrbSuccess] = useState<boolean>(false);
  const [showKeyActionTodo, setShowKeyActionTodo] = useState<boolean>(false);
  const [showNoBoostTimes, setShowNoBoostTimes] = useState<boolean>(false);
  const [boostSuccess, setBoostSuccess] = useState<boolean>(false);
  const [barkey, setBarKey] = useState<number>(0);
  const [showNextStage, setShowNextStage] = useState<boolean>(false);
  const [showBoostPrecondition, setShowBoostPrecondtion] =
    useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyTimer, setCopyTimer] = useState<NodeJS.Timeout | null>(null);

  // handle cache
  const [claimCache, setClaimCache] = useState<ClaimCahce | undefined>(
    undefined
  );
  const [boostCache, setBoostCache] = useState<boostCache | undefined>(
    undefined
  );
  const [beforeOpenCache, setBeforeOpenCache] = useState<
    BeforeOpenCache | undefined
  >(undefined);
  const [afterOpenCache, setAfterOpenCache] = useState<
    AfterOpenCache | undefined
  >(undefined);
  const [isBarFirstRender, setIsBarFirstRender] = useState<boolean>(true);
  const [{ run, spotLightRadius, steps }, setOnboardingState] =
    useSetState<EbOnBoardingState>({
      run: false,
      spotLightRadius: "8px",
      steps: [
        {
          content: (
            <div>
              <Trans i18nKey={"trans-eb-onboarding-step-1"}>
                Energy Orbs can bring you a variety of surprise rewards, which
                can be obtained by completing the following{" "}
                <KontosText>task list</KontosText> on this page, or by{" "}
                <KontosText>boosting other users</KontosText> and inviting them
                to <KontosText>boost you</KontosText> to gain more energy
                points.
              </Trans>
            </div>
          ),
          placement: "top",
          target: ".bar-helper",
          title: t("Energy Orbs reward progress bar"),
          disableBeacon: true,
          spotlightPadding: 0,
          offset: 2,
        },
        {
          content: (
            <React.Fragment>
              <span>
                {t(
                  "Rewards obtained by unlocking Energy Orbs and all the rewards you have claimed will be displayed in your backpack."
                )}
              </span>
            </React.Fragment>
          ),
          placement: "bottom",
          target: ".backpack-box",
          title: t("All claimed rewards are here!"),
          disableBeacon: true,
          offset: -5,
          spotlightPadding: 0,
        },
        {
          content: (
            <React.Fragment>
              <span>
                {t(
                  "Click the button immediately to copy the invitation link, send it to your friends to boost your account, and more surprise rewards are waiting for you to claim!"
                )}
              </span>
            </React.Fragment>
          ),
          placement: "top",
          target: ".share-button",
          title: t("Share to boost your energy!"),
          disableBeacon: true,
          offset: -5,
          spotlightPadding: 0,
          spotlightClicks: true,
        },
      ],
    });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Eb);
    }

    switch (index) {
      case 0:
        setOnboardingState({
          spotLightRadius: "8px",
        });
        break;
      case 1:
        setOnboardingState({
          spotLightRadius: "23.5px",
        });
        break;
      case 2:
        setOnboardingState({
          spotLightRadius: "27px",
        });
        break;
    }
  };

  const hasModalOpen: boolean = useMemo(() => {
    return (
      showBackpack ||
      showBoostDoneInviter ||
      showBoostPrecondition ||
      showCopySuccess ||
      showGetOrbs ||
      showKeyActionTodo ||
      showNextStage ||
      showNoAccount ||
      showNoBoostTimes ||
      showOpenOrbSuccess ||
      showPin ||
      showVideo
    );
  }, [
    showBackpack,
    showBoostDoneInviter,
    showBoostPrecondition,
    showCopySuccess,
    showGetOrbs,
    showKeyActionTodo,
    showNextStage,
    showNoAccount,
    showNoBoostTimes,
    showOpenOrbSuccess,
    showPin,
    showVideo,
  ]);

  useEffect(() => {
    if (
      ebStore.currentBarStage &&
      ebStore.ebAccount &&
      isBarFirstRender &&
      userStore.needOnboardingEb &&
      !inviter &&
      !hasModalOpen
    ) {
      setOnboardingState({
        run: true,
      });
      setIsBarFirstRender(false);
    }
  }, [
    ebStore.currentBarStage,
    ebStore.ebAccount,
    hasModalOpen,
    inviter,
    isBarFirstRender,
    setOnboardingState,
    userStore.needOnboardingEb,
  ]);

  const hasClaimBtn: boolean = useMemo(() => {
    return ebStore?.ebAccount && ebStore?.currentBarStage
      ? ebStore.ebAccount?.energyValue >
          ebStore.currentBarStage?.thresholds?.[0].value
      : true;
  }, [ebStore.currentBarStage, ebStore.ebAccount]);

  useEffect(() => {
    if (inviter) {
      ebStore.startTrackingInviterName();
      ebStore.setShowHelp(true);
      ebStore.setInviterName(inviter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: () => {
      setShowPin(false);
    },
    shouldClose: true,
  });

  useEffect(() => {
    return () => {
      if (copyTimer) {
        clearTimeout(copyTimer);
      }
    };
  }, [copyTimer]);

  const realDiscordPending: boolean = useMemo(() => {
    const isExist = ebStore.isDiscordSuccess;
    return isExist ? false : ebStore.discordPending;
  }, [ebStore.discordPending, ebStore.isDiscordSuccess]);

  const realTwitterPending: boolean = useMemo(() => {
    const isExist = ebStore.isTwitterSuccess;
    return isExist ? false : ebStore.twitterPending;
  }, [ebStore.isTwitterSuccess, ebStore.twitterPending]);

  useEffect(() => {
    if (showBoostPrecondition) {
      if (ebStore.isDiscordSuccess || ebStore.isTwitterSuccess) {
        setShowBoostPrecondtion(false);
      }
    }
  }, [
    ebStore.isDiscordSuccess,
    ebStore.isTwitterSuccess,
    showBoostPrecondition,
  ]);

  const handleShare = useCallback(async () => {
    setOnboardingState({ run: false });
    userStore.markOnboardingFinished(OnboardingType.Eb);

    if (!userStore.accountInfo) {
      toast({
        text: t(`Please wait a second...`),
        type: "warning",
      });
      return;
    }
    const { protocol, host } = window.location;
    const link = `${protocol}//${host}${location.pathname}?inviter=${userStore.accountInfo.name}`;
    if (navigator.share) {
      const flag = copy(link);
      if (flag) {
        toast({
          text: t("Successfully copied"),
          type: "success",
        });
        setCopySuccess(true);
        if (copyTimer) {
          clearTimeout(copyTimer);
        }
        const timer = setTimeout(() => {
          setCopySuccess(false);
        }, 1500);
        setCopyTimer(timer);
      } else {
        setCopySuccess(false);
      }
      try {
        await navigator?.share({
          title: t("Kontos Energy Boosting"),
          text: t("Help me for boosting!"),
          url: link,
        });
      } catch (e) {}
    } else {
      const flag = copy(link);
      if (flag) {
        setShowCopySuccess(true);
      } else {
        toast({
          text: t(`Copy failed, your link is`) + " " + link,
          type: "warning",
        });
      }
    }
  }, [setOnboardingState, userStore, location.pathname, t, copyTimer]);

  const noInviterNameFallback = useCallback(() => {
    throw ebError.noEbInviter;
  }, []);

  const noAccountFallback = useCallback(() => {
    throw ebError.noEbAccount;
  }, []);

  const noCliFallback = useCallback(() => {
    setShowPin(true);
    throw ebError.noCli;
  }, []);

  const handleSpecificError = useCallback((e: unknown, logMsg: string) => {
    if (e instanceof KontosError) {
      if (e.name === ebError.noCli.name) return;
      if (e.name === ebError.noEbAccount.name) {
        toast({
          type: "warning",
          text: e.message,
        });
        return;
      }
      if (e.name === ebError.noEbInviter.name) {
        toast({
          type: "warning",
          text: e.message,
        });
        return;
      }
    }
    console.log("Error log: " + logMsg, e);
    const errorMessage = e instanceof Error ? e.message : logMsg;
    toast({
      type: "error",
      text: errorMessage,
    });
  }, []);

  const checkBoostTimes = useCallback((): boolean => {
    const timesLeft = ebStore.ebAccount?.boostTimesLeft;
    console.log("timeleft", timesLeft);
    if (timesLeft === undefined || timesLeft <= 0) return false;
    return true;
  }, [ebStore.ebAccount?.boostTimesLeft]);

  const checkBoostAccount = useCallback(async (): Promise<boolean> => {
    if (!userStore.accountInfo) {
      try {
        loadingStore.showLoading();
        await userStore.fetchAndUpdateAccountInfoFromChain();
        await sleep(FETCH_ACCOUNT_INTERVAL);
      } catch (e) {
        toast({
          text: t("Failed to fetch account info"),
          type: "error",
        });
        return false;
      } finally {
        loadingStore.hideLoading();
      }
      if (!userStore.accountInfo) {
        return false;
      }
    }
    if (!ebStore.ebAccount) {
      try {
        loadingStore.showLoading();
        await ebStore.fetchAndSetEbAccount();
      } catch (e) {
        toast({
          text: t("Failed to fetch event account info"),
          type: "error",
        });
        return false;
      } finally {
        loadingStore.hideLoading();
      }
    }
    return true;
  }, [ebStore, t, userStore]);

  const checkBoostPrecondition = useCallback((): boolean => {
    if (!ebStore.ebAccount?.accountActions) return false;
    const requiredActions = ebStore.ebAccount.accountActions.actions
      .find((action) => action.category === EB_KEY_ACTION_CATEGORY)
      ?.actionItems?.filter(
        (item) =>
          ebTypes.EB_BOOST_NEED_ACTION_TYPES.includes(item.actionType) &&
          item.actionStatus === ebTypes.EB_ACTION_STATUS_SUCCESS
      );
    if (requiredActions?.length && requiredActions.length >= 1) return true;
    return false;
  }, [ebStore.ebAccount?.accountActions]);

  const handleBoost = useCallback(async () => {
    // This method should only be called when inviter exists
    if (!inviter) return;

    // Check if has account
    const accountCheckResult = await checkBoostAccount();
    if (!accountCheckResult) {
      const { pathname } = location;
      const url = `${pathname}?inviter=${inviter}`;
      userStore.updateRouteAfterAuth(url);
      setShowNoAccount(true);
      return;
    }

    // Cannot boost self
    if (isSameKontosName(inviter, userStore.accountInfo?.name)) {
      toast({
        text: t("You cannot boost yourself"),
        type: "warning",
      });
      setTimeout(() => {
        ebStore.setShowHelp(false);
      }, 1000);
      return;
    }

    // Check boost precondition, e.g. follow twitter, join discord, trade...
    const preconditionCheckResult = checkBoostPrecondition();
    if (!preconditionCheckResult) {
      setShowBoostPrecondtion(true);
      return;
    }

    // Check boost times left
    const timesCheckResult = checkBoostTimes();
    if (!timesCheckResult) {
      setShowNoBoostTimes(true);
      return;
    }

    // Execute
    try {
      loadingStore.showLoading();
      const boostValue = await ebStore.boost(
        noInviterNameFallback,
        noAccountFallback,
        noCliFallback
      );
      setBoostCache({ boostValue });
      loadingStore.hideLoading();
      setBoostSuccess(true);
      ebStore.addEnergyToEbInviterAccount(boostValue);
      // Here need an interval for bar animation display
      await sleep(BOOST_WAITING_TIME);
      setShowBoostDoneInviter(true);
    } catch (e) {
      handleSpecificError(e, t("Failed to boost"));
    } finally {
      loadingStore.hideLoading();
    }
  }, [
    checkBoostAccount,
    checkBoostPrecondition,
    checkBoostTimes,
    ebStore,
    handleSpecificError,
    inviter,
    location,
    noAccountFallback,
    noCliFallback,
    noInviterNameFallback,
    t,
    userStore,
  ]);

  const checkKeyAction = useCallback((): boolean => {
    const keyAction = ebStore.ebAccount?.accountActions?.actions?.find(
      (action) => action.category === EB_KEY_ACTION_CATEGORY
    );
    if (!keyAction) return false;
    const finishedNum = keyAction.actionItems.filter(
      (item) => item.actionStatus === EB_ACTION_STATUS_SUCCESS
    ).length;
    if (finishedNum === keyAction.actionItems.length) {
      return true;
    } else {
      return false;
    }
  }, [ebStore.ebAccount?.accountActions?.actions]);

  // Re render energy bar if last stage orb is claimed
  const checkStage = useCallback(
    (threshold: number) => {
      if (threshold === ebStore.currentBarStage?.end) {
        setBarKey((prevKey) => prevKey + 1);
        setShowNextStage(true);
      }
    },
    [ebStore.currentBarStage?.end]
  );

  const handleClaim = useCallback(
    async (
      threshold: number,
      energyOrbRewardType: number,
      energyOrbsRewardCount: number
    ) => {
      const keyActionFinished = checkKeyAction();
      if (!keyActionFinished) {
        setShowKeyActionTodo(true);
        return;
      }

      try {
        loadingStore.showLoading();
        setClaimCache({
          threshold: threshold,
          energyOrbRewardType: energyOrbRewardType,
          energyOrbsRewardCount: energyOrbsRewardCount,
        });
        await ebStore.claim(threshold, noAccountFallback, noCliFallback);
        await sleep(FETCH_ACCOUNT_INTERVAL);
        await ebStore.fetchAndSetEbBackpack();
        await ebStore.fetchAndSetEbAccount();
        setShowGetOrbs(true);
        checkStage(threshold);
      } catch (e) {
        handleSpecificError(e, t("Failed to claim energy orb"));
      } finally {
        loadingStore.hideLoading();
      }
    },
    [
      checkKeyAction,
      checkStage,
      ebStore,
      handleSpecificError,
      noAccountFallback,
      noCliFallback,
      t,
    ]
  );

  const handleOpenOrb = useCallback(
    async (energyOrbType: number) => {
      if (!userStore.accountInfo) {
        return;
      }
      if (!ebStore.ebBackpack?.energyOrbs?.[0]) {
        return;
      }
      try {
        setBeforeOpenCache({ energyOrbType });
        loadingStore.showLoading();
        const reward = await ebStore.openEnergyOrb(
          energyOrbType,
          noAccountFallback,
          noCliFallback
        );
        setAfterOpenCache({ reward });
        await sleep(FETCH_ACCOUNT_INTERVAL);
        await ebStore.fetchAndSetEbBackpack();
        setShowVideo(true);
      } catch (e) {
        handleSpecificError(e, t("Failed to open energy orb"));
      } finally {
        loadingStore.hideLoading();
      }
    },
    [
      ebStore,
      handleSpecificError,
      noAccountFallback,
      noCliFallback,
      t,
      userStore.accountInfo,
    ]
  );

  const handleOpenVideoFinished = useCallback(() => {
    setShowVideo(false);
    setShowOpenOrbSuccess(true);
  }, []);

  const handleAuth = useCallback(
    async (authType: number) => {
      try {
        loadingStore.showLoading();
        let url: string = "";
        switch (authType) {
          case ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD:
            url = await ebStore.authDiscord(noAccountFallback, noCliFallback);
            break;
          case ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER:
            url = await ebStore.authTwitter(noAccountFallback, noCliFallback);
            break;
          default:
            break;
        }
        if (!!url && url !== "") {
          openUrl(url);

          switch (authType) {
            case ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD:
              runInAction(() => {
                ebStore.setDiscordPending(true);
              });
              setTimeout(() => {
                ebStore.setDiscordPending(false);
              }, AUTH_TIMEOUT);
              break;

            case ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER:
              runInAction(() => {
                ebStore.setTwitterPending(true);
              });
              setTimeout(() => {
                ebStore.setTwitterPending(false);
              }, AUTH_TIMEOUT);
              break;
            default:
              break;
          }
        } else {
          toast({
            text: "No Url Provided: " + url,
            type: "error",
          });
        }
      } catch (e) {
        handleSpecificError(e, t("Failed to auth") + " " + authType);
      } finally {
        loadingStore.hideLoading();
      }
    },
    [ebStore, handleSpecificError, noAccountFallback, noCliFallback, t]
  );

  const hanldePINSuccess = useCallback(() => {
    setShowPin(false);
    if (ebStore.signType === "claim") {
      if (!claimCache) return;
      handleClaim(
        claimCache.threshold,
        claimCache.energyOrbRewardType,
        claimCache.energyOrbsRewardCount
      );
      return;
    }
    if (ebStore.signType === "boost") {
      handleBoost();
      return;
    }
    if (ebStore.signType === "openEnergyOrb") {
      if (!beforeOpenCache) return;
      handleOpenOrb(beforeOpenCache.energyOrbType);
      return;
    }
    if (ebStore.signType === "authDiscord") {
      handleAuth(ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD);
      return;
    }
    if (ebStore.signType === "authTwitter") {
      handleAuth(ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER);
      return;
    }
  }, [
    beforeOpenCache,
    claimCache,
    ebStore.signType,
    handleAuth,
    handleBoost,
    handleClaim,
    handleOpenOrb,
  ]);

  return (
    <Conatiner ref={wrapperRef} $hasClaimBtn={hasClaimBtn}>
      {/* Header */}
      {/* <EventHeader
        leftTitle={t("Kontos web app")}
        leftOnClick={handleOnBack}
        rightTitle={t("Boost History")}
        rightOnClick={handleViewHistory}
      /> */}

      {/* Ticker */}
      {/* {!showHelp && (
        <EnergyBoostingTicker notifications={ebStore.ebNotifications} />
      )} */}

      {/* Ticker */}

      {/* Account Panel */}
      <EbAccountPanel
        name={userStore.accountInfo?.name}
        credit={ebStore.credit}
        creditLevel={ebStore.creditLevel}
        creditPercentage={ebStore.creditPercentage}
        energyBallCount={ebStore.energyBallCount}
        onCheckBackpack={() => setShowBackpack(true)}
      />

      {/* Energy Orb Progress Bar */}
      {ebStore.currentBarStage && ebStore.ebAccount ? (
        <EbBarWrapper>
          <EbBar
            key={barkey}
            className="eb-bar"
            energyValue={ebStore.ebAccount.energyValue}
            stage={ebStore.currentBarStage}
            animationDuraion={BAR_ANIMATION_TIME}
            countUpDuration={BAR_ANIMATION_TIME}
            simplified={false}
            currentThreshold={ebStore.currentThreshold}
            onClaim={handleClaim}
          />
          {userStore.needOnboardingEb && (
            <EbbarOnboardingHelper className="bar-helper" />
          )}
        </EbBarWrapper>
      ) : (
        <Skeleton
          style={{
            height: "106px",
            margin: "0 24px",
            width: "calc(100% - 48px)",
            borderRadius: "8px",
          }}
        />
      )}

      {/* Share Button */}
      <EbShareBtnWrapper>
        <EbShareButton
          className="share-button"
          showSuccess={copySuccess}
          onClick={handleShare}
        />
      </EbShareBtnWrapper>

      {/* Actions */}
      <EbActionWrapper>
        {ebStore.sortedActions ? (
          ebStore.sortedActions.map((action) => (
            <EbActionBox
              key={action.category}
              actions={action}
              onAuth={handleAuth}
              twitterPending={ebStore.twitterPending}
              discordPending={ebStore.discordPending}
            />
          ))
        ) : (
          <Skeleton
            style={{
              height: "380px",
              width: "100%",
              borderRadius: "8px",
            }}
          />
        )}
      </EbActionWrapper>

      <div style={{ height: "27px" }} />

      {inviter && ebStore.showHelp && (
        <EbHelpPopup
          inviter={inviter}
          inviterEnergyValue={ebStore.inviterEnergyValue}
          inviterEnergyValueWithIncreasement={
            ebStore.inviterEnergyValueWithIncreasement
          }
          inviterEnergyPercent={ebStore.inviterEnergyPercentV2}
          inviterEnergyPercentWithIncreasement={
            ebStore.inviterEnergyPercentWithIncreasementV2
          }
          inviterEnergyIncreasement={ebStore.inviterEnergyIncreasement}
          inviterNextOrbNumber={ebStore.inviterNextOrbNumber}
          inviterNextOrbType={ebStore.inviterNextOrbType}
          inviterCurrentBarStage={ebStore.inviterCurrentBarStage}
          ebNotifications={ebStore.ebNotifications}
          onBoost={handleBoost}
          isSuccess={boostSuccess}
        />
      )}

      {/* Connect to Kontos Popup */}
      {inviter && showNoAccount && (
        <EbHelpLoginPopup inviterName={inviter} onClose={setShowNoAccount} />
      )}

      {/* Boost Self Popup */}
      {/* {showBoostDoneSelf && (
        <EbHelpDonePopup
          type={"self"}
          energyValue={boosterValue.toString()}
          onClose={setShowBoostDoneSelf}
        />
      )} */}

      {/* Boost Inviter Popup */}
      {showBoostDoneInviter && (
        <EbHelpDonePopup
          type={"inviter"}
          energyValue={boostCache?.boostValue.toString()}
          onClose={(isOpen) => {
            setShowBoostDoneInviter(isOpen);
            ebStore.setShowHelp(isOpen);
            setSearchParams();
          }}
        />
      )}

      {/* Claim Orb Popup */}
      {showGetOrbs && (
        <EbBarClaimPopup
          orbCount={claimCache?.energyOrbsRewardCount.toString()}
          orbType={claimCache?.energyOrbRewardType}
          onClose={setShowGetOrbs}
        />
      )}

      {!showGetOrbs && showNextStage && ebStore.currentBarStage?.thresholds && (
        <EbNewStagePopup
          thresholds={ebStore.currentBarStage?.thresholds}
          onClose={setShowNextStage}
        />
      )}

      {/* Copy Link Popup */}
      {showCopySuccess && <EbCopyPopup onClose={setShowCopySuccess} />}

      {/* Backpack */}
      {showBackpack && (
        <EbBackpackPopup
          energyOrbs={ebStore.ebBackpack?.energyOrbs || []}
          rewards={ebStore.ebBackpack?.rewards || []}
          loading={ebStore.ebBackpackLoading}
          onClose={setShowBackpack}
          onOpenOrb={handleOpenOrb}
        />
      )}

      {/* Open Orb Animation */}
      {showVideo && (
        <EbOpenOrbOverlay
          onVideoEnd={handleOpenVideoFinished}
          orbType={beforeOpenCache?.energyOrbType!}
          rewardRarity={"normal"}
        />
      )}

      {/* Open Orb Success Popup */}
      {showOpenOrbSuccess && (
        <EbOpenOrbSuccessPopup
          title={
            new KontosNumber(
              afterOpenCache?.reward.rewardItem.assetAmount,
              DEFAULT_DECIMAL
            ) +
            " " +
            afterOpenCache?.reward.rewardItem.assetSymbol
          }
          // subTitle={afterOpenCache?.reward.rewardItem.assetDesc}
          subTitle={undefined}
          img={afterOpenCache?.reward.rewardItem.assetImageUrl}
          onClose={setShowOpenOrbSuccess}
        />
      )}

      {/* Lack Key Action No Claim Popup */}
      {showKeyActionTodo && (
        <EbLackKeyActionPopup onClose={setShowKeyActionTodo} />
      )}

      {/* No boost times Popup */}
      {showNoBoostTimes && (
        <EbNoBoostTimesPopup
          dailyLimit={ebStore.ebAccount?.boostCount || 30}
          onClose={() => {
            setShowNoBoostTimes(false);
            ebStore.setShowHelp(false);
            setSearchParams();
          }}
        />
      )}

      {/* Lack Boost Precondition Popup */}
      {showBoostPrecondition && (
        <EbNoBoostPreconditionPopup
          onAuth={(authType) => {
            setShowBoostPrecondtion(false);
            handleAuth(authType);
          }}
          onClose={setShowBoostPrecondtion}
          discordPending={realDiscordPending}
          twitterPending={realTwitterPending}
        />
      )}

      {/* PIN */}
      <BottomSheet
        isOpen={showPin}
        onClose={() => setShowPin(false)}
        mountPoint={domNode}
        disableScrollLocking={true}
      >
        <Unlock onSuccess={hanldePINSuccess} />
      </BottomSheet>

      {userStore.needOnboardingEb && (
        <Joyride
          callback={handleJoyrideCallback}
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          floaterProps={{
            styles: { arrow: { color: "#fff", spread: 20, length: 14 } },
          }}
          styles={{
            options: {
              zIndex: 2000000,
            },
            spotlight: {
              borderRadius: spotLightRadius,
            },
            overlay: {
              backgroundColor: "rgba(0, 13, 31, 0.30)",
            },
          }}
          tooltipComponent={JoyRideToolTip}
        />
      )}
    </Conatiner>
  );
});
