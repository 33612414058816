import React from "react";
import { ToastContainer as Container } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const StyledContainer = styled(Container)<{ top?: number }>`
  top: ${({ top }) => top}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  .Toastify__toast {
    flex-direction: row;
    padding: 0;

    border-radius: 8px;
    border: 1px solid var(--Deep-50, #edeef1);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.1);
    backdrop-filter: blur(5px);
    filter: drop-shadow(6px 6px 10px rgba(0, 13, 31, 0.1));

    min-height: auto;
    width: 343px;

    z-index: 999999999 !important;

    .Toastify__toast-container {
      z-index: 999999999; /* 你的自定义z-index值 */
    }
    .Toastify__toast-container {
      z-index: 999999999 !important;
    }

    .Toastify__toast-icon {
      height: 16px;
      width: auto;
      margin-right: 12px;
    }

    .Toastify__toast-body {
      flex-direction: row;
      margin: 0;
      padding: 20px 16px;
    }
  }
  .Toastify--animate-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;
export const TOAST_CONTAINER_ID = "toast";
// handle https://fkhadra.github.io/react-toastify/introduction

interface ToastContainerProps {
  id?: string;
  top?: number;
}

const ToastContainer: React.FC<ToastContainerProps> = ({
  id = TOAST_CONTAINER_ID,
  top = 0,
}) => {
  return (
    <StyledContainer
      top={top}
      enableMultiContainer
      containerId={id}
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      closeButton={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={3}
    />
  );
};

export default ToastContainer;
