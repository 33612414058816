import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import FloatingButton from "src/pages/trade/FloatingButton";
import addIcon from "src/assets/icons/add3.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getMultiAccountsBalances } from "src/service/account-service";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import AccountItem from "./AccountItem";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL, DEFAULT_DISPLAY_PRECESION } from "src/config";
import {
  isSameAddress,
  isSameKontosName,
  prioritizeItems,
} from "src/utils/zkkontosHelper";
import Sheet from "react-modal-sheet";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import { observer } from "mobx-react";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import AddAccount from "./AddAccount";
import deleteIcon from "src/assets/icons/settings/delete.svg";
import toast from "src/components/toast/Toast";
import DelAccount from "./DelAccount";
import { loadingStore } from "src/store/loadingStore";
import RecAccount from "./RecAccount";
import { useStores } from "src/hooks/useStore";
import { action } from "mobx";

const Container = styled.div`
  // height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 0 16px 16px 16px;

  @keyframes slideIn {
    from {
      transform: translateX(100%-82px);
    }
    to {
      transform: translateX(-82px);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(-82px);
    }
    to {
      transform: translateX(100%-82px);
    }
  }

  .slide-in {
    animation: slideIn 0.5s forwards;
  }

  .slide-out {
    animation: slideOut 0.5s forwards;
  }
`;

const Scrollable = styled.div`
  margin-top: 8px;

  flex: 1;
  overflow: auto;

  margin-bottom: 32px;
`;

const AccountItemWrapper = styled.div`
  /* flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  overflow-x: hidden;
`;

const DeleteBtn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -82px;
  margin: auto;

  > img {
    width: 82px;
    height: 100px;
  }

  &:hover {
    opacity: 0.9;
  }

  cursor: pointer;
`;

const FloatingBtnContainer = styled.div`
  position: sticky;
  bottom: 16px;
  width: 89%;
  z-index: 1;
  margin: 0 auto;
`;

type iDisplayAccount = {
  index: number;
  type: "normal" | "recover";
  usdAmount: string | undefined;
  name: string;
  address: string;
  recovering: boolean;
};

type iDisplayAccountBalance = {
  account: string;
  usdAmount: string;
};

const updateAccountsWithBalance = (
  _accountsBalances: iDisplayAccountBalance[],
  _accounts: iDisplayAccount[]
): iDisplayAccount[] => {
  const newAccounts = _accounts.map((account) => {
    const accountBalance = _accountsBalances.find((ab) =>
      isSameAddress(ab.account, account.address)
    );
    if (accountBalance) {
      return {
        ...account,
        usdAmount: accountBalance.usdAmount,
      };
    }
    return account;
  });
  return newAccounts;
};
const SwitchAccount: React.FC = () => {
  const { userStore, uiSettingStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [managing, setManaging] = useState<boolean>(false);
  const [animationClass, setAnimationClass] = useState("");
  const [activeAccount, setActiveAccount] = useState<iDisplayAccount>();

  const [accountsBalances, setAccountsBalances] = useState<
    iDisplayAccountBalance[]
  >([]);
  const currentAccounts: iDisplayAccount[] = useMemo(() => {
    return userStore.storageKeys?.map((item, index) => {
      return {
        index: index,
        type: userStore.accountsRecovering?.includes(item.accountName)
          ? "recover"
          : "normal",
        name: item.accountName,
        address: KontosQueryCli.nameAddress(item.accountName),
        usdAmount: undefined,
        recovering: userStore.accountsRecovering?.includes(item.accountName),
      };
    });
  }, [userStore.storageKeys, userStore.accountsRecovering]);
  const accounts: iDisplayAccount[] = useMemo(() => {
    let res = currentAccounts;
    if (accountsBalances.length > 0) {
      res = updateAccountsWithBalance(accountsBalances, res);
    }
    res = prioritizeItems(res, userStore.accountInfo?.name!);
    return res;
  }, [accountsBalances, currentAccounts, userStore.accountInfo?.name]);

  const closeModals = useCallback(() => {
    uiSettingStore.closeAddAccountModal();
    uiSettingStore.closeRemoveAccountModal();
    uiSettingStore.closeRecoverDetailModal();
  }, [uiSettingStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedAccounts = userStore.storageKeys?.map(
          (item) => item.accountName
        );
        const resp = await getMultiAccountsBalances(storedAccounts);
        const accountsBalances = resp.accountTotalUsdAmount;
        setAccountsBalances(accountsBalances);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [userStore.storageKeys]);

  const handleSwitchClick = useCallback(
    async (account: iDisplayAccount) => {
      if (account.type !== "normal") {
        return;
      }
      if (account.name === userStore.accountInfo?.name) {
        return;
      }
      setAnimationClass("");
      await userStore.switchAccount(account.index);
      toast({
        text: t(`inter_switch_account_toast`, {
          name: account.name,
        }),
        type: "success",
      });
    },
    [userStore]
  );

  const hanldeAddOnClick = useCallback(() => {
    uiSettingStore.showAddAccountModal();
  }, [uiSettingStore]);

  const toggleVisibility = useCallback(() => {
    if (managing) {
      setAnimationClass("slide-out");
      setManaging(false);
    } else {
      setAnimationClass("slide-in");
      setManaging(true);
    }
  }, [managing]);

  const handleDelOnClick = useCallback(
    (account: iDisplayAccount) => {
      setActiveAccount(account);
      uiSettingStore.showRemoveAccountModal();
    },
    [uiSettingStore]
  );

  const handleRemoveAccount = useCallback(() => {
    uiSettingStore.closeRemoveAccountModal();
    if (activeAccount && activeAccount.type === "normal") {
      userStore.removeAccount(activeAccount.name);
      toast({
        text: `Successfully removed account ${activeAccount.name}.os`,
        type: "success",
      });
      setActiveAccount(undefined);
      return;
    }

    if (activeAccount && activeAccount.type === "recover") {
      userStore.removeAccount(activeAccount.name);
      toast({
        text: `Successfully removed account ${activeAccount.name}.os`,
        type: "success",
      });
      setActiveAccount(undefined);
      return;
    }
  }, [activeAccount, uiSettingStore, userStore]);

  const handleRecoveringItemClick = useCallback(
    (account: iDisplayAccount) => {
      setActiveAccount(account);
      uiSettingStore.showRecoverDetailModall();
    },
    [uiSettingStore]
  );

  const handleRecoverSuccess = useCallback(async () => {
    uiSettingStore.closeRecoverDetailModal();
    if (!activeAccount) {
      return;
    }
    loadingStore.showLoading();
    try {
      const cli = await userStore.getKontosQueryCli();
      const smartAccount = await cli.getSmartAccountByName(activeAccount.name);
      const pubKey = smartAccount.pubKey;
      const allStorageKeys = userStore.storageKeys;
      const kontosKey = allStorageKeys.find((key) =>
        isSameKontosName(key.accountName, activeAccount.name)
      );
      if (!kontosKey) {
        toast({
          type: "error",
          text: t("There is no matching key locally"),
        });
        return;
      }
      const compressedPubKey = kontosKey.pubKeyData.compressedPubKey;
      if (compressedPubKey === pubKey) {
        const accountsRecovering = [...userStore.accountsRecovering] || [];
        const index = accountsRecovering?.indexOf(activeAccount.name);
        if (index > -1) {
          accountsRecovering?.splice(index, 1);
          userStore.updateAccountsRecovering(accountsRecovering);
        }
      } else {
        toast({
          type: "error",
          text: t("Public key recovery exception, please try again later"),
        });
        return;
      }
    } catch (e) {
      console.log(e);
      const errorMessage =
        e instanceof Error
          ? e.message
          : t("An exception occurred during the recovery process");
      toast({
        type: "error",
        text: errorMessage,
      });
    } finally {
      loadingStore.hideLoading();
    }
  }, [activeAccount, uiSettingStore, userStore]);

  return (
    <Container ref={wrapperRef}>
      <Header
        title={t("Switch Account")}
        enableBack={true}
        headStyle={{ marginTop: "-20px" }}
        rightBtnText={
          managing ? (t("Cancel") as string) : (t("Manage") as string)
        }
        rightBtnCallBack={toggleVisibility}
      />
      <Scrollable>
        {accounts.map((account) => (
          <AccountItemWrapper key={account.name} style={{}}>
            <AccountItem
              style={{
                flex: "1",
                transition: "transform 0.5s ease",
                transform:
                  managing && account.name !== userStore.accountInfo?.name
                    ? "translateX(-91px)"
                    : "translateX(0)",
              }}
              recovering={account.recovering}
              name={account.name}
              avatar={avatarIcon}
              balanceText={
                account.usdAmount
                  ? new KontosNumber(
                      account.usdAmount,
                      DEFAULT_DECIMAL
                    ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"
                  : undefined
              }
              selected={account.name === userStore.accountInfo?.name}
              disable={managing}
              onClick={() => {
                handleSwitchClick(account);
              }}
              onBottomClick={() => {
                handleRecoveringItemClick(account);
              }}
            />
            {account.name !== userStore.accountInfo?.name && (
              <DeleteBtn
                className={`${animationClass} delbtn`}
                onClick={() => handleDelOnClick(account)}
              >
                <img src={deleteIcon} alt="delete" />
              </DeleteBtn>
            )}
          </AccountItemWrapper>
        ))}
      </Scrollable>
      <FloatingBtnContainer>
        <FloatingButton
          fixedIconRight={false}
          onClick={hanldeAddOnClick}
          fixedIcon={addIcon}
        >
          {t("Add Account")}
        </FloatingButton>
      </FloatingBtnContainer>

      <NoScrollerBarSheet
        isOpen={uiSettingStore.isAddAccountModalOpen}
        onClose={action(() => uiSettingStore.closeAddAccountModal())}
        mountPoint={domNode}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div
              style={{
                height: 330,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <AddAccount />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={action(() => uiSettingStore.closeAddAccountModal())}
        />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={uiSettingStore.isRemoveAccountModalOpen}
        onClose={action(() => uiSettingStore.closeRemoveAccountModal())}
        mountPoint={domNode}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div
              style={{
                height: 330,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <DelAccount
                name={activeAccount?.name!}
                balanceText={
                  activeAccount?.usdAmount
                    ? new KontosNumber(
                        activeAccount.usdAmount,
                        DEFAULT_DECIMAL
                      ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"
                    : undefined
                }
                onRemove={handleRemoveAccount}
                onCancel={action(() =>
                  uiSettingStore.closeRemoveAccountModal()
                )}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => uiSettingStore.closeRemoveAccountModal()}
        />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={uiSettingStore.isRecoverDetailModalOpen}
        onClose={action(() => uiSettingStore.closeRecoverDetailModal())}
        mountPoint={domNode}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div
              style={{
                height: 482,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <RecAccount
                name={activeAccount?.name!}
                onSuccess={handleRecoverSuccess}
                onCancel={action(() =>
                  uiSettingStore.closeRecoverDetailModal()
                )}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={action(() => uiSettingStore.closeRecoverDetailModal())}
        />
      </NoScrollerBarSheet>
    </Container>
  );
};

export default observer(SwitchAccount);
