import {
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_ACCOUNT_REFERRAL,
  ROUTE_SETTINGS_ACCOUNT_SWITCH,
  ROUTE_SETTINGS_PAYMENT,
  ROUTE_SETTINGS_TRADE,
} from "src/router/router-config";
import { Route, Routes, useNavigate } from "react-router-dom";
import AccountSettings from "./account/AccountSettings";
import SwitchAccount from "./account/switch-account/SwitchAccount";
import { useEffect } from "react";
import { useStores } from "src/hooks/useStore";
import AccountReferral from "src/pages/settings/account/AccountReferral";
import { TradeSetting } from "./trade/TradeSetting";
import { PaymentMethod } from "./payment/PaymentMethod";

const SettingsRouter: React.FC = () => {
  const { uiSettingStore, userStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    uiSettingStore.reset();
    return () => {
      uiSettingStore.reset();
    };
  }, [uiSettingStore]);

  useEffect(() => {
    userStore.clearEmailRecoveringAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {/* account */}
      <Route path={ROUTE_SETTINGS_ACCOUNT} element={<AccountSettings />} />
      <Route
        path={ROUTE_SETTINGS_ACCOUNT + ROUTE_SETTINGS_ACCOUNT_SWITCH}
        element={<SwitchAccount />}
      />
      <Route
        path={ROUTE_SETTINGS_ACCOUNT + ROUTE_SETTINGS_ACCOUNT_REFERRAL}
        element={<AccountReferral />}
      />
      <Route
        path={ROUTE_SETTINGS_ACCOUNT + ROUTE_SETTINGS_PAYMENT}
        element={<PaymentMethod onDone={() => navigate(-1)} />}
      />

      {/* trade */}
      <Route
        path={":tradeType/" + ROUTE_SETTINGS_TRADE}
        element={<TradeSetting />}
      />
      <Route
        path={":tradeType/" + ROUTE_SETTINGS_TRADE + ROUTE_SETTINGS_PAYMENT}
        element={
          <PaymentMethod
            onDone={() => uiSettingStore.closeTradeSettingSheet()}
          />
        }
      />
      <Route
        path={ROUTE_SETTINGS_PAYMENT}
        element={
          <PaymentMethod
            onDone={() => uiSettingStore.closeTradeSettingSheet()}
          />
        }
      />
    </Routes>
  );
};

export default SettingsRouter;
