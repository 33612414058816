import React, { useEffect, useRef, useMemo, useCallback } from "react";
import styled from "styled-components";
import headIco from "src/assets/icons/account/accountIco.svg";
import homeIco1 from "src/assets/icons/homeIco1.svg";
import headIco2 from "src/assets/icons/homeIco2.svg";
import headIco3 from "src/assets/icons/homeIco5.svg";
import headIco4 from "src/assets/icons/homeIco4.svg";
import { t } from "i18next";
import { useState } from "react";
import { AssetDetailQuote } from "src/components/assets-detail/AssetDetailQuote";
import { observer } from "mobx-react-lite";
import arrowIco from "src/assets/icons/arrow12.svg";
import ReceiveRouter from "../receive/ReceiveRouter";
import {
  ROUTE_SEND,
  ROUTE_HOME,
  ROUTE_MAIN,
  ROUTE_MAIN_RECEIVE_CHOOSE,
  ROUTE_QR_READER,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_PAYMENT,
  ROUTE_TRADE,
} from "src/router/router-config";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import SettingsRouter from "../settings/SettingsRouter";
import errIcon from "src/assets/icons/error.svg";
import { PopupModal } from "src/components/popups/PopupModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { EbBanner } from "../events/energy-boosting/EbBanner";
import { useStores } from "src/hooks/useStore";
import LinkButton from "../main/LinkButton";
import { SheetView } from "src/store/SheetStore";
import { UnlockSheet } from "../sign-up/unlock/UnlockSheet";
import SignSheet from "src/sheets/dapps/SignSheet";
import { Flag } from "src/configs/featureFlags";
import HomeBg from "src/components/bg/HomeBg";
import MarketsRanking from "src/components/markets-ranking/MarketsRanking";
import { AssetsList } from "src/components/list/assetList";
import { PopupMarketRankings } from "src/components/popups/PopupMarketRankings";
import { TabWrapper } from "src/components/tab";
import settingsSvg from "src/assets/icons/settings.svg";
import { OTCModal } from "src/components/otc/OTC";
import {
  AssetLabel,
  getFtAssetsLabel,
  getFtAssetsV3,
} from "src/apis/markets-apis";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { QuoteWrapper } from "src/components/assets-detail/QuoteWrapper";
import MarketsChainRanking from "src/components/markets-ranking/MarketsChainRanking";
import SkeletonMarketAssetList from "src/components/skeleton/SkeletonMarketAssetList";
import { FtAsset } from "@/type/zkkontos";
import { switchAccountPath } from "src/router/Router";
import SyncIcon from "src/components/sync/SyncIcon";
import {
  ToBuyAssetSelector as SyncAssetSelector,
  ShowAssetType,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { syncAccount } from "src/apis/explorer-apis";
import toast from "src/components/toast/Toast";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import { NoNetworkPopup } from "src/components/popups/NoNetworkPopup";
import { useSetState } from "react-use";
import Joyride, { CallBackProps, Step, STATUS } from "react-joyride";
import {
  JoyRideToolTip,
  KontosText,
} from "src/components/onboarding/JoyRideTooltip";
import onboardingWcImg from "src/assets/images/onboarding/onboarding-wc.png";
import { OnboardingType } from "src/store/localKeeper";
import { Trans } from "react-i18next";
import { ChainAssetList } from "src/components/asset-list/ChainAssetList";
import { SingleIconWithTextButton } from "src/components/button/SingleIconWithTextButton";
import managePaymentIcon from "src/assets/icons/trade/payment-manage.svg";
import managePaymentActiveIcon from "src/assets/icons/trade/payment-manage-active.svg";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { TaskOrActivityList } from "src/components/task-activity/TaskOrActivityList";
import { openUrl } from "src/utils/helper";

const Wrapper = styled.div<{ $isFixedTab: boolean }>`
  box-sizing: border-box;
  /* padding: 20px 0 0 0; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;

  .link-btn,
  .settings-btn {
    position: absolute;
    top: 24px;
    right: 16px;
    z-index: 10;
  }

  .settings-btn {
    cursor: pointer;
  }

  > .head {
    position: fixed;
    width: 100%;
    top: 0;
    padding: 20px 20px 24px 20px;
    display: flex;
    flex-direction: column;

    z-index: 2;

    .normal-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .logo {
        margin-right: 6px;

        .logo-avatar {
          cursor: pointer;
          width: 32px;
          height: 32px;
        }
      }

      .name {
        overflow: hidden;
        color: var(--Deep-800, #1a2535);
        text-overflow: ellipsis;
        font-family: HarmonyOS Sans;
        font-size: 16px;
      }
    }
    .exception-line {
      margin: 0 -20px;
      background-color: rgba(255, 30, 43, 0.1);
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      .exception-line-icon {
        width: 14px;
        height: 14px;
      }

      .exception-line-text {
        margin-left: 8px;

        color: var(--error-notice, #ff1e2b);
        font-family: "HarmonyOS Sans";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .mainPageContent {
    // padding-top: 24px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-ms-scrollbar {
      width: 0;
    }
    > div {
      width: 100%;
    }

    .event-banner {
      margin-bottom: 10px;
    }

    .listWrapperFixed {
      height: 100%;
      display: flex;
      padding-top: 50px;
      overflow-y: ${(props) => (props.$isFixedTab ? "auto" : "hidden")};
      background-color: #fff;
    }
  }

  .totalPrice {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    height: 54px;
    padding: 0 24px;
    .l {
      flex: 1;
      overflow: hidden;
      color: var(--Deep-800, #1a2535);
      //text-overflow: ellipsis;
      font-family: HarmonyOS Sans Bold;
      font-size: 36px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .symbol {
        overflow: hidden;
        color: var(--Deep-800, #1a2535);
        text-overflow: ellipsis;
        font-family: HarmonyOS Sans Bold;
        font-size: 22px;
        font-style: normal;
        line-height: normal;
        margin-left: 8px;
        transform: translateY(7px);
      }
    }

    .r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--Deep-400, #80868f);
      text-overflow: ellipsis;
      cursor: pointer;

      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      padding-top: 13px;
      img {
        margin-left: 5px;
      }
    }
  }

  .nav {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div,
    > a {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 16px;
      text-decoration: none;
    }

    .ico {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin-bottom: 4px;
    }

    .text {
      margin: 0;
      padding: 0;
      color: var(--Deep-400, #80868f);
      text-align: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .bannerWrap {
    padding: 0 16px;
  }
`;

const TabContainer = styled.div<{ $isFixed?: boolean; topOffset?: number }>`
  padding: 10px 28px 0 28px;
  width: 100%;

  overflow-y: ${(props) => (props.$isFixed ? "auto" : "hidden")};
  position: ${(props) => (props.$isFixed ? "fixed" : "relative")};
  left: 0;
  top: ${(props) => (props.$isFixed ? `${props.topOffset}px` : "hidden")};
  // padding: 26px 28px 0;
  z-index: 2;
  background: ${(props) => (props.$isFixed ? "#fff" : "transparent")};
`;

const ListWrapper = styled.div<{ $whiteBg?: boolean; $needRightGap?: boolean }>`
  background-color: ${(props) => (props.$whiteBg ? "#fff" : "transparent")};
  padding: ${(props) =>
    props.$needRightGap ? "0 10px 60px 20px" : "0 20px 60px"};
  width: 100%;
  // height: 347px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;

  > div,
  > span {
    width: 100%;
  }
`;

const SyncBtnOnboardingHelper = styled.div`
  position: absolute;
  top: 0;
  left: -6px;
  width: calc(100% + 12px);
  height: 100%;
`;

interface HomeOnboardingState {
  homeRun: boolean;
  homeSteps: Step[];
  homeSpotLightRadius: string;
}

interface AssetsOnboardingState {
  assetsRun: boolean;
  assetsSteps: Step[];
  assetsSpotLightRadius: string;
}

interface TabItem {
  name: string;
  label: string;
}

export const ASSETS: TabItem = { name: "asset", label: t("Assets") };
export const TASK: TabItem = { name: "task", label: t("Task") };
export const ACTIVITY: TabItem = { name: "activity", label: t("Activity") };
const TABS: TabItem[] = [ASSETS, TASK, ACTIVITY];

const HomePage: React.FC = observer(() => {
  const {
    userStore,
    receiveStore,
    uiSettingStore,
    sheetStore,
    marketStore,
    chainStore,
    dappConnectStore,
  } = useStores();
  const navArr = [
    {
      ico: homeIco1,
      text: t("Send"),
      value: "send",
    },
    {
      ico: headIco2,
      text: t("Receive"),
      value: "receive",
    },
    {
      ico: headIco3,
      text: t("Bridge"),
      value: "bridge",
      url: `https://bridge.kontos.io?account=${userStore.accountName}`,
    },
    {
      ico: headIco4,
      text: t("OTC"),
      value: "otc",
    },
  ];

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const domNode = wrapperRef.current as Element | undefined;
  // scroll container
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [distanceBetweenMainAndTab, setDistanceBetweenMainAndTab] =
    useState<number>(0);
  const [isFixedTab, setIsFixedTab] = useState(false);
  const [tabTop, setTabTop] = useState<number>(0);
  // asset detail
  const [assetQuoteDetailShow, setAssetQuoteDetailShow] = useState(false);
  const [otcShow, setOtcShow] = useState(false);
  // receive
  const [showReceiveModal, setShowReceiveModal] = useState<boolean>(false);
  // account settings
  const [showAccountSettingsModal, setShowAccountSettingsModal] =
    useState<boolean>(false);
  // Pub key warn
  const [showPubKeyWarnPopup, setShowPubKeyWarnPopup] =
    useState<boolean>(false);
  // No network warn
  const [showNoNetworkWarnPopup, setShowNoNetworkPopup] =
    useState<boolean>(false);
  // Show Energy Booster Banner
  const [showEbBanner, setShowEbBanner] = useState<boolean>(Flag.eb);
  const ebBannerRef = useRef<HTMLDivElement | null>(null);
  const [showPopupMarketsRanking, setShowPopupMarketsRanking] =
    useState<boolean>(false);
  const isHomePage = useMemo(
    () => location.pathname.startsWith(ROUTE_HOME),
    [location]
  );
  const isHomePageOrMainPageFullyMatch = useMemo(() => {
    return location.pathname === ROUTE_HOME || location.pathname === ROUTE_MAIN;
  }, [location]);

  const isQrReaderOpen = sheetStore.sheetVisibility.get(
    SheetView.QrReader
  ) as boolean;
  // market data
  const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
  const [ftAssets, setFtAssets] = useState<FtAsset[]>([]);
  const [loadingFtAssets, setLoadingFtAssets] = useState<boolean>(false);
  const selectedCategory = marketStore.selectedCategory;
  const [selectedChain, setSelectedChain] = useState<string>("");
  const [marketPageCount, setMarketPageCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  // quote modal
  const [searchParams, setSearchParams] = useSearchParams();
  const tabBySearchParams = searchParams.get("tab") || "";
  const [tab, setTab] = useState<TabItem>(
    TABS.find((item) => item.name === tabBySearchParams) || ASSETS
  );
  const showQuote = searchParams.get("showQuote");
  const backToHome = useCallback(() => {
    navigate(!isHomePage ? ROUTE_MAIN : ROUTE_HOME);
  }, [isHomePage, navigate]);
  const [isAsyncModalOpen, setIsAsyncModalOpen] = useState(false);
  const [{ homeRun, homeSpotLightRadius, homeSteps }, setHomeOnboardingState] =
    useSetState<HomeOnboardingState>({
      homeRun: false,
      homeSpotLightRadius: "99px",
      homeSteps: [
        {
          content: (
            <React.Fragment>
              <span>{t("Scan the WalletConnect QR code or click here")}</span>
              <img
                src={onboardingWcImg}
                style={{ width: "288px", height: "46px" }}
                alt="diagram"
              />
              <span>
                {t(
                  "Copy the WalletConnect link into the address box on the page to connect to the DApp."
                )}
              </span>
            </React.Fragment>
          ),
          placement: "bottom-end",
          target: ".link-btn",
          title: t("Explore the world of blockchain DApps"),
          disableBeacon: true,
          spotlightPadding: 0,
          offset: 0,
        },
        {
          content: (
            <React.Fragment>
              <span>
                {t(
                  "You can now participate in the Energy Orbs event launched by Kontos, click here to go to the event page immediately!"
                )}
              </span>
            </React.Fragment>
          ),
          placement: "bottom",
          target: ".event-banner",
          title: t("Energy Orbs event is now online!"),
          disableBeacon: true,
          spotlightPadding: 0,
          offset: 0,
        },
      ],
    });

  const [
    { assetsRun, assetsSpotLightRadius, assetsSteps },
    setAssetsOnboardingState,
  ] = useSetState<AssetsOnboardingState>({
    assetsRun: false,
    assetsSpotLightRadius: "8px",
    assetsSteps: [
      {
        content: (
          <div>
            <Trans i18nKey={"trans-assets-onboarding-step-1"}>
              Now, you can proactively sync your balance by clicking on{" "}
              <KontosText>"Sync",</KontosText> then selecting the specific asset
              to proceed.
            </Trans>
          </div>
        ),
        placement: "top-end",
        target: ".sync-btn-helper",
        title: t("Initiate your asset synchronization!"),
        disableBeacon: true,
        spotlightPadding: 8,
        offset: 7,
      },
    ],
  });

  const handleHomeJoyrideCallback = (data: CallBackProps) => {
    const { index, status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Home);
    }

    switch (index) {
      case 0:
        setHomeOnboardingState({
          homeSpotLightRadius: "99px",
        });
        break;
      case 1:
        setHomeOnboardingState({
          homeSpotLightRadius: "8px",
        });
        break;
    }
  };

  const handleAssetsJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Assets);
    }
  };

  useEffect(() => {
    if (
      !userStore.pubKeyException &&
      !userStore.noNetwork &&
      userStore.needOnboardingHome &&
      isHomePage
    ) {
      setHomeOnboardingState({ homeRun: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.needOnboardingHome, isHomePage]);

  useEffect(() => {
    if (
      !userStore.pubKeyException &&
      !userStore.noNetwork &&
      userStore.needOnboardingAssets &&
      !isHomePage
    ) {
      setAssetsOnboardingState({ assetsRun: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.needOnboardingAssets, isHomePage]);

  useEffect(() => {
    if (location.pathname === switchAccountPath && userStore.pubKeyException) {
      if (!showAccountSettingsModal) {
        setShowPubKeyWarnPopup(true);
        setShowAccountSettingsModal(true);
      }
    } else if (
      !showReceiveModal &&
      !showAccountSettingsModal &&
      !isQrReaderOpen &&
      !uiSettingStore.showTradeSettingSheet &&
      searchParams.size === 0
    ) {
      backToHome();
    }
  }, [
    backToHome,
    showReceiveModal,
    showAccountSettingsModal,
    isQrReaderOpen,
    location.pathname,
    userStore.pubKeyException,
    uiSettingStore.showTradeSettingSheet,
    searchParams.size,
  ]);

  useEffect(() => {
    // handleLoadMoreItems();
    if (isHomePageOrMainPageFullyMatch) {
      setShowReceiveModal(false);
      setShowAccountSettingsModal(false);
      uiSettingStore.closeTradeSettingSheet();
      sheetStore.closeSheetByView(SheetView.QrReader);
    }
  }, [isHomePageOrMainPageFullyMatch, sheetStore, uiSettingStore]);

  const closeModals = useCallback(() => {
    setShowReceiveModal(false);
    setShowAccountSettingsModal(false);
    uiSettingStore.closeTradeSettingSheet();
    setShowPubKeyWarnPopup(false);
    setOtcShow(false);
  }, [uiSettingStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: uiSettingStore.canClose,
  });

  useEffect(() => {
    const pair = async (wcLink: string, retryCount: number = 5) => {
      if (!dappConnectStore.client && retryCount > 0) {
        setTimeout(() => pair(wcLink, retryCount - 1), 500);
        return;
      }
      try {
        await dappConnectStore.onPair(wcLink);
      } catch (error) {
        console.error("Pairing error:", error);
      }
    };

    const wc = searchParams.get("wc");
    const expiryTimestamp = searchParams.get("expiryTimestamp");
    const relayProtocol = searchParams.get("relay-protocol");
    const symKey = searchParams.get("symKey");

    if (wc && expiryTimestamp && relayProtocol && symKey) {
      const link = `wc:${wc}?expiryTimestamp=${expiryTimestamp}&relay-protocol=${relayProtocol}&symKey=${symKey}`;
      pair(link);
    }
  }, [dappConnectStore, searchParams, sheetStore]);

  // For tab sticky
  /**
   * x <= a
   * x + y = b
   * -->
   * b - y <= a
   */
  useEffect(() => {
    if (isHomePage) {
      return;
    }

    const handleResize = () => {
      if (
        wrapperRef.current &&
        tabRef.current &&
        scrollContainerRef.current &&
        headerRef.current
      ) {
        scrollContainerRef.current.scrollTop = 0;
        const scrollContainerScrollTop = scrollContainerRef.current.scrollTop;
        const containerTop = wrapperRef.current.getBoundingClientRect().top;
        const tabTop = tabRef.current.getBoundingClientRect().top;

        setDistanceBetweenMainAndTab(
          scrollContainerScrollTop + tabTop - containerTop
        );
      }
    };

    const handleScroll = () => {
      if (distanceBetweenMainAndTab <= 0) {
        return;
      }

      if (wrapperRef.current && tabRef.current && scrollContainerRef.current) {
        const containerTop = wrapperRef.current.getBoundingClientRect().top;
        const scrollContainerTop =
          scrollContainerRef.current.getBoundingClientRect().top;
        const realDistance = scrollContainerTop - containerTop;

        const scrollContainerScrollTop = scrollContainerRef.current.scrollTop;

        setIsFixedTab(
          distanceBetweenMainAndTab - scrollContainerScrollTop <
            realDistance + 1
        );
        setTabTop(realDistance);
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      if (distanceBetweenMainAndTab <= 0) {
        // After header's height is calculated into tabTop
        setTimeout(() => {
          handleResize();
        }, 0);
      }
      scrollContainer.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [distanceBetweenMainAndTab, isHomePage]);

  // For market data load
  useEffect(() => {
    if (!isHomePage) {
      return;
    }

    function handleScroll() {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        // load data
        if (
          !loadingFtAssets &&
          hasMore &&
          scrollHeight - scrollTop - clientHeight < 100
        ) {
          setMarketPageCount(marketPageCount + 1);
        }
      }
    }

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, isHomePage, loadingFtAssets, marketPageCount]);

  // change page
  useEffect(() => {
    setIsFixedTab(false);
    if (scrollContainerRef.current) scrollContainerRef.current.scrollTop = 0;
  }, [isHomePage]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (userStore.noNetwork && !showNoNetworkWarnPopup) {
        setShowNoNetworkPopup(true);
        event.stopPropagation();
        return;
      }

      if (userStore.pubKeyException && !showPubKeyWarnPopup) {
        event.stopPropagation();
        navigate(switchAccountPath);
        return;
      }
    };

    const rootElement = wrapperRef.current;
    if (rootElement) {
      rootElement.addEventListener("click", handleClick);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("click", handleClick);
      }
    };
  }, [
    navigate,
    showNoNetworkWarnPopup,
    showPubKeyWarnPopup,
    userStore.noNetwork,
    userStore.pubKeyException,
  ]);

  useEffect(() => {
    if (userStore.noNetwork && !showNoNetworkWarnPopup) {
      setShowNoNetworkPopup(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const loadMore = async () => {
      if (!isHomePage) return;
      try {
        setLoadingFtAssets(true);
        if (marketPageCount === 1) {
          setFtAssets([]);
        }
        if (marketStore.categoryArr.length === 0) {
          setLoadingCategory(true);
          const rs = await getFtAssetsLabel({});
          const ftAssetsCategory = rs.assetLabels.filter(
            (item) => item.chainIndex === ""
          );
          const chainArr = rs.assetLabels.filter(
            (item) => item.chainIndex !== ""
          );
          marketStore.setCategoryArr(ftAssetsCategory);
          marketStore.setChainArr(chainArr);
          setLoadingCategory(false);
          return;
        }
        const { ftAssets } = await getFtAssetsV3({
          chainIndex: "",
          category: selectedCategory,
          subcategory: selectedChain,
          offset: (marketPageCount - 1) * pageSize,
          limit: pageSize,
          isRecommended: selectedCategory === 1,
          inWhoseWatchlist:
            selectedCategory === 0 ? userStore.accountInfo?.name : undefined,
        });
        // marketStore.updateVotes({
        //   bullishVotes: marketPredictInfo.bullish,
        //   bearishVotes: marketPredictInfo.bearish,
        //   action: marketPredictInfo.action,
        // });
        setLoadingFtAssets(false);
        setFtAssets((prevItems) => [...prevItems, ...(ftAssets || [])]);
        if (!ftAssets || ftAssets.length < pageSize) {
          setHasMore(false);
          return;
        }
      } catch (e) {
        console.log("get market data err: ", e);
        setLoadingFtAssets(false);
        setLoadingCategory(false);
      }
    };
    loadMore();
  }, [
    isHomePage,
    selectedCategory,
    selectedChain,
    marketStore.categoryArr,
    marketPageCount,
    marketStore,
    userStore.accountInfo?.name,
  ]);

  const handleTabChange = (current: TabItem) => {
    setTab(current);
  };

  const initListData = () => {
    setFtAssets([]);
    setMarketPageCount(1);
    setHasMore(true);
  };

  const handleRankingItemClick = useCallback(
    (item: AssetLabel) => {
      if (item.category === selectedCategory || loadingFtAssets) {
        return;
      }
      initListData();
      marketStore.setSelectedCategory(item.category);
    },
    [selectedCategory, loadingFtAssets, marketStore]
  );

  return (
    <Wrapper ref={wrapperRef} $isFixedTab={isFixedTab}>
      <HomeBg />
      <div className={"head"} ref={headerRef}>
        <div className="normal-line">
          <div className={"logo"}>
            <img
              className="logo-avatar"
              src={headIco}
              onClick={(event) => {
                if (userStore.pubKeyException) return;
                event.stopPropagation();
                setShowAccountSettingsModal(true);
                navigate(ROUTE_SETTINGS_ACCOUNT);
              }}
              alt=""
            />
          </div>
          <div className={"name"}>{userStore.accountInfo?.name || ""}.os</div>
        </div>
        {userStore.pubKeyException && (
          <div className="exception-line">
            <img className="exception-line-icon" src={errIcon} alt="" />
            <span className="exception-line-text">
              {t("You need to reset and recover account now")}
            </span>
          </div>
        )}
      </div>
      <div
        className={"mainPageContent"}
        ref={scrollContainerRef}
        style={{ marginTop: `${headerRef.current?.offsetHeight}px` }}
      >
        <div className={"totalPrice"}>
          <div className={"l"}>
            {userStore.balanceInUSD ? (
              userStore.balanceInUSD
            ) : (
              <Skeleton count={1} style={{ width: "120px" }} />
            )}{" "}
            <span className={"symbol"}>USD</span>
          </div>
          {isHomePage && (
            <div
              className={"r"}
              onClick={() => {
                navigate(ROUTE_MAIN);
              }}
            >
              {t("View assets")}
              <img src={arrowIco} alt="" />
            </div>
          )}
        </div>
        <div className={"nav"}>
          {navArr.map((item, index) => (
            <div
              key={index.toString()}
              onClick={() => {
                if (userStore.pubKeyException) return;
                switch (item.value) {
                  case "send":
                    navigate(ROUTE_SEND);
                    // setIsSendOpen(true);
                    break;
                  case "receive":
                    receiveStore.restore();
                    setShowReceiveModal(true);
                    navigate(ROUTE_MAIN_RECEIVE_CHOOSE);
                    break;
                  case "trade":
                    navigate(ROUTE_TRADE);
                    break;
                  case "bridge":
                    openUrl(
                      `https://bridge.kontos.io?account=${userStore.accountName}`
                    );
                    break;
                  case "otc":
                    setOtcShow(true);
                    break;
                }
              }}
            >
              <img className={"ico"} src={item.ico} alt="" />
              <p className={"text"}>{item.text}</p>
            </div>
          ))}
        </div>

        {showEbBanner && isHomePage && (
          <div
            ref={ebBannerRef}
            style={isFixedTab ? { visibility: "hidden" } : {}}
            className={"bannerWrap"}
          >
            <EbBanner className="event-banner" onClose={setShowEbBanner} />
          </div>
        )}
        {!isHomePage && <div style={{ height: "12px" }} />}

        {!isHomePage && (
          <>
            <TabContainer ref={tabRef} $isFixed={isFixedTab} topOffset={tabTop}>
              <TabWrapper>
                {[ASSETS, TASK, ACTIVITY].map((item) => (
                  <div
                    key={item.name}
                    className={tab === item ? "item current" : "item"}
                    onClick={() => handleTabChange(item)}
                  >
                    <span>{t(item.label)}</span>
                  </div>
                ))}
                <SyncIcon
                  click={() => {
                    setIsAsyncModalOpen(true);
                  }}
                  styles={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                >
                  <SyncBtnOnboardingHelper className="sync-btn-helper" />
                </SyncIcon>
              </TabWrapper>
            </TabContainer>
            <div
              className={`listWrapper ${isFixedTab ? "listWrapperFixed" : ""}`}
            >
              {tab.name === ASSETS.name && (
                <ListWrapper $whiteBg $needRightGap>
                  {/* {userStore.accountBalances?.ftAssetTypes?.map(
                    (item, index) => (
                      <AssetsList
                        item={item}
                        key={index.toString()}
                        handleClick={() => {
                          setAssetDetailShow(true);
                          setCurrentAssetDetail(item);
                        }}
                      />
                    )
                  )} */}
                  <ChainAssetList
                    assets={userStore.userHoldings}
                    chains={chainStore.chainsSortedByUserHoldings}
                    wrapperStyle={{
                      marginRight: "10px",
                    }}
                  />

                  {/* Manage Payment Floating Button */}
                  {!isHomePage && (
                    <SingleIconWithTextButton
                      wrapperStyle={{
                        margin: "24px 0 16px 0",
                        width: "initial",
                      }}
                      content={t("Manage Payment")}
                      icon={managePaymentIcon}
                      activeIcon={managePaymentActiveIcon}
                      size={16}
                      onClick={(event) => {
                        if (userStore.pubKeyException) return;
                        event.stopPropagation();
                        uiSettingStore.openTradeSettingSheet();
                        navigate(ROUTE_SETTINGS_PAYMENT);
                      }}
                    />
                  )}
                </ListWrapper>
              )}

              {tab.name === TASK.name && (
                <ListWrapper $whiteBg $needRightGap>
                  <TaskOrActivityList
                    key={userStore.accountNameWithOs}
                    accountName={userStore.accountNameWithOs || ""}
                    type="task"
                    scrollStyle={{
                      marginRight: "10px",
                    }}
                  />
                </ListWrapper>
              )}

              {tab.name === ACTIVITY.name && (
                <ListWrapper $whiteBg $needRightGap>
                  <TaskOrActivityList
                    key={userStore.accountNameWithOs}
                    accountName={userStore.accountNameWithOs || ""}
                    type="activity"
                    scrollStyle={{
                      marginRight: "10px",
                    }}
                  />
                </ListWrapper>
              )}
            </div>
          </>
        )}

        {isHomePage && (
          <>
            {/*<MarketOverviewWrapper>*/}
            {/*  <div className={'text'}>*/}
            {/*    {*/}
            {/*      t('Market Overview')*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <img src={arrowIco} />*/}
            {/*</MarketOverviewWrapper>*/}
            {loadingCategory && (
              <Skeleton
                count={1}
                style={{
                  display: "inline-block",
                  height: "30px",
                  width: "70px",
                  margin: "0 24px",
                }}
              />
            )}
            {!loadingCategory && (
              <MarketsRanking
                selectedCategory={selectedCategory}
                itemClick={(item) => {
                  handleRankingItemClick(item);
                }}
                styles={{
                  marginBottom: "16px",
                }}
                moreBtnClick={() => {
                  setShowPopupMarketsRanking(true);
                }}
              />
            )}
            {!loadingCategory && (
              <MarketsChainRanking
                styles={{
                  paddingLeft: "24px",
                }}
                selectedChain={selectedChain}
                itemClick={(item) => {
                  if (loadingFtAssets) return;
                  initListData();
                  setSelectedChain(item.chainIndex);
                }}
              />
            )}
            <ListWrapper>
              {ftAssets.map((item, index) => (
                <AssetsList
                  item={{
                    name: item.name,
                    symbol: item.symbol,
                    imageUrl: item.imageUrl,
                    desc: "",
                    assetType: 0,
                    ftAssets: [],
                    totalUsd: item.h24Volume,
                  }}
                  chainUrl={item.chainGreyImageUrl}
                  fluctuate={item.h24PriceChangePercentage}
                  isMarket={true}
                  usdPrice={item.usdPrice}
                  chainSymbol={item.chainSymbol}
                  key={index.toString()}
                  handleClick={() => {
                    marketStore.setCurrentAssetQuote(item);
                    setSearchParams({
                      ...searchParams,
                      ftAssetId: item.ftAssetId.toString(),
                      chainIndex: item.chainIndex,
                      ftAssetAddress: item.address,
                      showQuote: "1",
                      hideNavigationBar: "1",
                    });
                  }}
                  index={index}
                  showRanking={true}
                  isVerified={item.isGreatLiquidity === 1}
                  volumeUnit={t("Vol: ")}
                  isGreatLiquidity={item.isGreatLiquidity}
                  securityLevel={item.securityLevel}
                />
              ))}
              {loadingFtAssets && (
                <>
                  <div style={{ height: "18px" }} />
                  <SkeletonMarketAssetList
                    length={ftAssets.length === 0 ? 6 : 3}
                  />
                </>
              )}
              {!loadingFtAssets && !hasMore && <></>}
              {!loadingFtAssets && ftAssets.length === 0 && (
                <NoDataV2
                  style={{
                    paddingTop: "50px",
                    alignItems: "center",
                  }}
                  text={t("No data")}
                />
              )}
            </ListWrapper>
          </>
        )}
      </div>
      {/* LinkBtn */}
      {Flag.dapp && isHomePage && (
        <LinkButton
          className="link-btn"
          onPasteClick={() => {
            if (userStore.pubKeyException) return;
            sheetStore.openSheet(SheetView.DappConnection);
          }}
          onScanClick={() => {
            if (userStore.pubKeyException) return;
            sheetStore.openSheet(SheetView.QrReader);
            navigate(ROUTE_QR_READER);
          }}
        />
      )}
      {showPopupMarketsRanking && (
        <PopupMarketRankings
          selectedCategory={selectedCategory}
          onConfirm={(item) => {
            handleRankingItemClick(item);
            setShowPopupMarketsRanking(false);
          }}
          onCancel={() => {
            setShowPopupMarketsRanking(false);
          }}
          onOutClick={() => {
            setShowPopupMarketsRanking(false);
          }}
          title={t("All Rankings")}
        />
      )}

      {/* asset detail Quote*/}
      <BottomSheet
        isOpen={assetQuoteDetailShow}
        onClose={() => setAssetQuoteDetailShow(false)}
        mountPoint={domNode}
      >
        <AssetDetailQuote handleBack={() => setAssetQuoteDetailShow(false)} />
      </BottomSheet>

      {/* otc*/}
      <BottomSheet
        isOpen={otcShow}
        onClose={() => setOtcShow(false)}
        mountPoint={domNode}
      >
        <OTCModal handleBack={() => setOtcShow(false)} />
      </BottomSheet>

      <BottomSheet
        isOpen={showReceiveModal}
        onClose={() => setShowReceiveModal(false)}
        mountPoint={domNode}
      >
        <ReceiveRouter />
      </BottomSheet>

      {/* account settings */}
      <BottomSheet
        isOpen={
          showAccountSettingsModal || uiSettingStore.showTradeSettingSheet
        }
        onClose={() => {
          setShowAccountSettingsModal(false);
          uiSettingStore.closeTradeSettingSheet();
        }}
        mountPoint={domNode}
      >
        <SettingsRouter />
      </BottomSheet>

      <BottomSheet
        isOpen={isAsyncModalOpen}
        onClose={() => setIsAsyncModalOpen(false)}
        mountPoint={domNode}
      >
        <SyncAssetSelector
          onChoose={async (item: FtAsset) => {
            try {
              setIsAsyncModalOpen(false);
              await syncAccount({
                account: userStore.accountInfo?.name || "",
                chainIndex: item.chainIndex,
                assetAddress: item.address,
              });
              userStore.fetchAndUpdateAccountBalances();
              toast({
                text: t("Sync Success"),
                type: "success",
                autoClose: 3000,
              });
            } catch (e) {
              toast({
                text: t("Sync fail" + e),
                type: "error",
                autoClose: 3000,
              });
            }
          }}
          chains={chainStore.chains}
          hasRecommend={true}
          hasAll={true}
          hasFavorites={true}
          showAssetType={ShowAssetType.Sync}
          initAssetType={NonChainIndex.MyAssets}
          hasMyAssets={true}
          isSync={true}
        />
      </BottomSheet>

      {/* pub key exception modal */}
      {showPubKeyWarnPopup && (
        <PopupModal
          type="error"
          onConfirm={() => {
            userStore.removeAccount(userStore.accountInfo?.name || "", true);
            navigate(
              ROUTE_RECOVER_ACCOUNT + "?name=" + userStore.accountInfo?.name
            );
            setShowPubKeyWarnPopup(false);
          }}
          onCancel={(event) => {
            setShowPubKeyWarnPopup(false);
          }}
          onOutClick={(event) => {
            setShowPubKeyWarnPopup(false);
          }}
          title={t("Account Warning !")}
          desc={t(
            "Some issues with your public key, the account can not make transactions any more."
          )}
          subDesc={"*Please reset and recover your account first."}
          btnTxt={t("Reset Now") as string}
          cancelBtnText={t("Later") as string}
        />
      )}

      {/* no network exception modal */}
      {showNoNetworkWarnPopup && (
        <NoNetworkPopup
          accountName={userStore.accountName}
          onRetry={userStore.retryConnectToNetwork}
          onClose={() => setShowNoNetworkPopup(false)}
        />
      )}

      {!isHomePage && (
        <div className={"settings-btn"}>
          <img
            src={settingsSvg}
            onClick={() => {
              navigate(ROUTE_SETTINGS);
            }}
            alt=""
          />
        </div>
      )}

      {/* Unlock */}
      <UnlockSheet mountPoint={domNode} />

      {/* Sign */}
      <SignSheet mountPoint={domNode} />

      {/* Quote Page */}
      {showQuote && (
        <QuoteWrapper>
          <AssetDetailQuote />
        </QuoteWrapper>
      )}

      {/* Onboarding */}
      {userStore.needOnboardingHome && isHomePage && (
        <Joyride
          callback={handleHomeJoyrideCallback}
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          continuous
          run={homeRun}
          showProgress
          showSkipButton
          steps={homeSteps}
          floaterProps={{
            styles: {
              arrow: {
                color: "#fff",
                spread: 20,
                length: 14,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 2000000,
            },
            spotlight: {
              borderRadius: homeSpotLightRadius,
            },
            overlay: {
              backgroundColor: "rgba(0, 13, 31, 0.30)",
            },
          }}
          tooltipComponent={JoyRideToolTip}
        />
      )}

      {/* Beginner guidance */}
      {userStore.needOnboardingAssets && !isHomePage && (
        <Joyride
          callback={handleAssetsJoyrideCallback}
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          continuous
          run={assetsRun}
          showProgress
          showSkipButton
          steps={assetsSteps}
          floaterProps={{
            styles: {
              arrow: {
                color: "#fff",
                spread: 20,
                length: 14,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 2000000,
            },
            spotlight: {
              borderRadius: assetsSpotLightRadius,
            },
            overlay: {
              backgroundColor: "rgba(0, 13, 31, 0.30)",
            },
          }}
          tooltipComponent={JoyRideToolTip}
        />
      )}
    </Wrapper>
  );
});

export default HomePage;
